.content-pages {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
}

.content-pages h1 {
  font-size: 21px;
}

.container-slider {
  height: 450px;
  overflow: hidden;
}

.container-logo-slider {
  padding: 30px 25px;
  background-image: url("https://fondosmil.com/fondo/1867.jpg");
  background-size: cover;
  height: 100%;
  border-bottom: white 1px solid;
}

.container-logo-slider .container-logo-sslider {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.container-logo-slider .logo {
  width: 100%;
  display: block;
}

.rsv-slider-separador {
  margin-top: -37%;
  position: relative;
  width: 100%;
  /* height: 410px; */
  z-index: 2;
}

.form-reserva {
  margin-top: 50px;
}

.form-input {
  position: relative;
}

.form-input svg {
  position: absolute;
  width: 18px;
  top: 13px;
  left: 15px;
  fill: #00aed8;
  z-index: 1;
}

.input-form {
  margin-top: 10px;
  margin-bottom: 10px;
}

.input-form label {
  font-size: 14px;
  color: #6b6b6b;
  font-weight: 700;
  margin-bottom: 5px;
}

.input-form input {
  border: 1px solid #e8e8e8;
  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px 10px 50px !important;
  border-radius: 10px;
  width: 100%;
  color: black;
  font-size: 14px;
}

.input-form textarea {
  max-height: 200px;
  min-height: 100px;
  border: 1px solid #e8e8e8;
  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px 10px 20px !important;
  border-radius: 10px;
  width: 100%;
  color: black;
  font-size: 14px;
}

.container-price {
  text-align: center;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.container-price .moneda {
  font-weight: 700;
}

.container-price .price {
  color: #00aed8;
  font-size: 60px;
  font-weight: 700;
}

.select-search-container {
  width: 100% !important;
}

.select-search-container input {
  background: white  !important;
  border: 1px solid #e8e8e8 !important;
}

.select-search-select {
  background: white  !important;
  z-index: 3px !important;
}

.select-search-select button {
  background: white;
  color: black;
}

.select-search-select .select-search-group-header {
  background: white;
  color: black;
}

.select-search-select ul {
  background: white  !important;
}

.select-search-select ul li {
  background: white  !important;
}

.react-calendar {
  background: #00aed8 !important;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 10px;
  color: white !important;
  margin: auto;
}

.react-calendar__navigation button:enabled:focus {
  background-color: #0090b3;
}

.react-calendar__navigation button {
  color: white;
}

.react-calendar__tile {
  color: white;
}

.react-calendar__month-view__days__day--weekend {
  color: #d30909;
}

.react-calendar__tile--now {
  color: black;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #4a98bd;
}

.am-appointment-times {
  margin: 8px 0;
  max-height: 0px;
  overflow-y: auto;
  max-height: 0px;
  margin-top: 20px;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.el-radio-button {
  margin: 5px 2px;
  cursor: pointer;
}

.el-radio-button__orig-radio {
  display: none;
}

.el-radio-button__inner {
  border: 1px solid #367a86;
  border-radius: 3px;
  background: transparent;
  color: #026d80;
  font-size: 12px;
  font-weight: 300;
  padding: 8px;
  opacity: 0.7;
  width: 126px;
  font-weight: 600;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.el-radio-button__inner.active {
  color: white;
  background: #00aed8;
  border: 1px solid #00aed8;
}

.el-radio-button__inner:hover {
  color: white;
  background: #00aed8;
  border: 1px solid #00aed8;
}

.open {
  max-height: 136px;
}

.prueba {
  width: 100px;
  height: 100px;
  background: black;
  border-radius: 50%;
}

.Cld label {
  color: #a1a1a1;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}

.Cld div {
  font-size: 18px;
  font-weight: 500;
}
