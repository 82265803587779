
.ajuste-nav{
  justify-content: end !important
}
.container-menu{
  position: fixed;
  width: 100%;
  z-index: 999;
  padding-bottom: 5px;
  transition: 0.5s;
}
.menu-flags-button{
  border:0px !important;
  padding: 0px !important;
}
.ReactFlagsSelect-module_selectOptions__3LNBJ{
  padding-left: 10px !important;
}

input[name="rfs-q"]{
  margin-bottom: 10px !important;
  
}

p{
  font-weight: 400;
  font-size: 15px;
  color: #5e5e5e;
}
.container-menu-nav{
  position: fixed;
  background: white;
  height: 100%;
  top: 0;
  left: -600px;
  transition: 0.5s;
  width: 240px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px
}

.container-menu-nav .container-logo{
  padding: 30px 25px;
}
.container-menu-nav .logo{
  width: 100%;
}
.container-menu-nav .container-close-menu{
  padding: 20px 10px;
}
.container-menu-nav .container-close-menu .close {
  width: 15px;
  float: right;
  display: block;
  cursor: pointer;
}
.container-menu-nav.active{
  left : 0;
}

.menu-opacity{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000054;
  display: none;
}

.menu-opacity.active{
  display: block;
}
.container-menu-nav ul {
  padding: 0px;
}

.container-menu-nav li {
  padding: 0px;
}

.container-menu-nav .li-icons{
  width: 30px;
  margin-right: 10px;
  margin-top: -4px
}
.container-menu-nav .li-element {
  list-style: none;
  display: block;
  padding: 10px 17px;
  color: #6e6e6e;
  text-decoration: none;
  border-bottom: 1px solid #cccccc;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
}
.container-menu-nav .li-element.active{ 
  background:#1f2a491f;
}
.menu{
  margin-bottom: 0px;
  padding: 0px;
}
.menu li{
  list-style: none;
}
.icon-menu{
  cursor: pointer;
}

.redes-sociales{
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin-top: 50%;
}
.redes-sociales a{
  display: inline-block;
  width: 30px;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
}

.redes-sociales a img{
  width: 100%;
}

.btn-1{
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #00aed8;
  border: 0px;
  padding: 10px;
  color: white;
  border-radius: 20px;
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
}

.btn-1:hover{
  background: #079cc1;
}

.btn-2{
  background: white;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  color: #5d5d5d;
  font-size: 15px;
  height: 45px;
  font-weight: 700;
  display: block;
  margin: auto;
  width: 100%;
}

.LeyendaError{
  color: rgb(248, 71, 71);
  font-weight: 600;
}

button:disabled {
  pointer-events: auto! important;
  cursor: not-allowed! important;
  background: rgb(158, 158, 158);
}

.modal-header, .modal-footer{
  border: 0px !important;
}

.modal-footer{
  justify-content: center !important;
}

.modal-body{
  text-align: center;
}

.background-nav{
  background: #00aed8;
}
.d-none{
  display: none !important;
}

.reserva-disabled{
  border: 1px solid #757676 !important;
  color: #757676 !important;
}
.reserva-disabled:hover{
  background: white !important;
}


.react-calendar__tile:disabled {
  background-color: #007997 !important;
}

.react-calendar__navigation button:disabled {
  background-color: #007997 !important;
}

.react-calendar__tile--active {
  background: #efefef !important;
  color: #2a2a2a !important;
}

.disabled, .disabled:hover{
  background: rgb(151, 151, 151);
  cursor: unset !important;
}
