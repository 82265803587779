.slider
    background-image: url('../../images/f_6.png')
    background-size: cover
    background-position: 10% 10%
    max-width: 100%
    height: 100vh
    overflow: auto

.content
    padding-left: 20px
    padding-right: 20px
    padding-top: 60px
    padding-bottom: 50px
.logo-slider
    width: 150px
    margin-left: auto
    margin-right: auto
    margin-top: 60px
    margin-bottom: 40px
    display: block
.banner-home
    max-width: 500px
    margin-right: auto
    margin-left: auto
    display: flex
    padding: 0
    margin-bottom: 25px
    background: #d3d3d3
    box-shadow: rgba(120, 120, 120, 0.25) 0px 6px 20px -2px, rgba(169, 169, 169, 0.30) 0px 9px 7px 0px
    
    border-radius: 11px

    .banner-slider
        margin-left: -1px
        margin-right: -62px
        padding: 0
        height: 95px
        width: 150px
        .banner-img
            height: 119px
            width: 150px
            margin-top: -14px
            margin-left: -12px
    .banner-content
        text-align: center
        background: white
        -webkit-clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%)
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 43px 100%)
        flex: 1 1 auto!important
        border-radius: 0px 8px 8px 0px
        display: table
        .banner-content-body
            font-size: 12px
            display: table-cell
            vertical-align: middle
            width: 100%
            a
                text-decoration: none
            h2
                font-size: 16px
                margin: 2px
                font-weight: 700
                color: #1f2a49
        .banner-button
            background: #00aed8
            color: white
            font-size: 12px
            font-weight: 600
            margin: 7px auto
            text-align: center
            padding: 6px 15px
            border-radius: 5px
            border: 0px
            text-decoration: none
            letter-spacing: 1.5px
            display: inline-flex
        .banner-button:hover
            background: #029ec4

.qr
    display: flex
    position: relative
    justify-content: center
    margin-top: 50px
    .qr-content
        position: relative
        width: 165px
        height: 165px
        border-top: 3px solid #3665f1
        background-image: url('../../images/i_1.png')
        background-size: cover
        border-radius: 50%
        overflow: hidden
        z-index: 2
        
        .qr-body
            color: white
            font-size: 18px
            font-weight: 500
            top: 25px
            z-index: 2
            text-align: center
            position: absolute
            width: 135px
            margin-left: 16px
        .capa
            width: 100%
            height: 100%
            position: relative
            background: #0000002e
            z-index: 1
    .qr-btn-ver
        position: relative
        text-align: center
        margin-top: -29px
        margin-right: auto
        margin-left: auto
        /* margin: auto; */
        z-index: 1
        width: 130px
        img
            width: 100%
